import axios from 'axios';

export const getText = async (code, setText, setError) => {
    await axios.get(`${process.env.REACT_APP_API_URL}/texts/${code}`)
    .then(res => {
      const { text } = res.data.data;
      setText(text)
    })
    .catch(error => setError());
}

export const sentText = async (text, setSuccess, setError) => {
    await axios.post(`${process.env.REACT_APP_API_URL}/texts`, {
        text
    })
    .then(res => {
        setSuccess(res.data.data)
    })
    .catch(error => setError(error));
}