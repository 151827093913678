import React from 'react'

import { Layout } from 'antd'
import FooterApp from '../components/Footer'
import HeaderApp from '../components/Header'

import ContentApp from './Content'

const MainApp = () => {
    return (
        <Layout id='MainLayOut'>
            <HeaderApp/>
            <ContentApp/>
            <FooterApp/>
        </Layout>
    )
}

export default MainApp