import './App.css';
import MainApp from './pages/Main';

function App() {
  return (
    <MainApp/>
  );
}

export default App;
