import React from 'react';

const TimerCount = ({ timer }) => {
  const formatTime = () => {
    const min = Math.floor(timer / 60);
    const sec = timer - min * 60;
    return `${min.toString().padStart(2, '0')}:${sec.toString().padStart(2, '0')}`;
  };

  return <span id='Timer'>{formatTime()}</span>;
};

export default TimerCount;