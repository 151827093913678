import React from 'react'

import { Layout, Col, Row, Space } from 'antd'
import SentCard from '../components/SentCard'
import RecievedCard from '../components/RecievedCard'

const { Content } = Layout
const ContentApp = () => {
    return (
        <Content id='Content'>
            <Row>
                <Col flex="460px">
                    <Space direction="vertical" size={26} id='CardSpace'>'
                        <SentCard/>
                        <RecievedCard/>
                    </Space>
                </Col>
                <Col flex="auto">
                    <h1 className='Headline' id='MainHeadLine'>FREE TEXT TRANSFER</h1>
                    <div className='Headline'>FAST</div>
                    <div className='Headline'>SECURE</div>
                    <div className='Headline'>NO ACCOUNT NEEDED</div>
                    <div className='Headline2'>SEND TEXT ANYWHERE ANYTIME</div>
                </Col>
            </Row>
        </Content>
    )
}

export default ContentApp