import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Space, Form, message, QRCode, Spin } from 'antd';
import { SendOutlined, RollbackOutlined } from '@ant-design/icons';
import TimerCount from './TimerCounter';
import { sentText } from '../api/textApi';
import {socket} from '../api/socket';

const { TextArea } = Input;

const SentCard = () => {
    const [sendDisableStatus, setSendDisableStatus] = useState(false);
    const [successStatus, setSuccessStatus] = useState(false);
    const [otpValue, setOTPValue] = useState('123456');
    const [messageApi, contextHolder] = message.useMessage();
    const [isLoading, setIsLoading] = useState(false);
    const [timer, setTimer] = useState(0);
    const [form] = Form.useForm();

    useEffect(() => {
        setSendDisableStatus(false);
    }, [form]);

    useEffect(() => {
        const onConnect = () => {
            console.log("Connected to socket.io")
        }

        const onDisconnect = () => {
            console.log("Disconnected to socket.io")
        }

        socket.on("connect_error", (err) => {
            console.log(`connect_error due to ${err.message}`);
          });
        socket.on("connect", onConnect)
        socket.on("connect", onConnect)

        return () => {
            socket.off('connect', onConnect);
            socket.off('disconnect', onDisconnect);
          };
    }, []);

    const onTimerDone = () => {
        setSuccessStatus(false);
    };

    const setSocketListen = (id) => {
        socket.on(id, (msg) => {
            const {code} = msg;
            messageApi.info(`Code ${code}: A user has copied the text`);
        })
    }

    const onSend = async (values) => {
        setIsLoading(true);
        try {
            await sentText(values.text, (res) => {
                setSuccessStatus(true);
                setOTPValue(res.code);
                setTimer(res.time);
                setSocketListen(res.id);
                const timerInterval = setInterval(() => {
                    setTimer((time) => {
                        if (time === 0) {
                            clearInterval(timerInterval);
                            onTimerDone();
                            return 0;
                        } else return time - 1;
                    });
                }, 1000);
            }, (err) => {
                console.log(err)
                messageApi.error('Something went wrong!')});
        } catch (error) {
            messageApi.error('Something went wrong!');
        }
        finally {
            setIsLoading(false);
        }
    };

    const onChangeValue = () => {
        const text = form.getFieldValue('text');
        setSendDisableStatus(text.length === 0);
    };

    const onBack = () => {
        setSuccessStatus(false);
        setTimer(0);
    };

    const onCopied = () => {
        navigator.clipboard.writeText(otpValue);
        messageApi.success('Copied!');
    };

    const renderOTP = () => {
        return otpValue.split('').map((e, i) => (
            <div
                className='PinDiv'
                key={i}
            >
                {e}
            </div>
        ));
    };

    return (
        <Card hoverable>
            {contextHolder}
            {successStatus ? (
                <Space direction="vertical" size="middle" className='DisplayFlex'>
                    <div className='CardTitle'>Sent!</div>
                    <span id='TimerDesc'>
                        Enter the 6-digit key on the receiving device.{' '}
                        <span id='FontBoldWeight'>
                            Expires in: <TimerCount timer={timer} />{' '}
                        </span>
                    </span>
                    <Space className='DisplayFlex' size="middle" onClick={onCopied}>
                        {renderOTP()}
                    </Space>
                    <QRCode value={`${window.location.origin}?code=${otpValue}`} size={200} />
                    <Button
                        danger
                        block
                        type="primary"
                        size="large"
                        className='ButtonDiv'
                        onClick={onBack}
                    >
                        Back
                        <RollbackOutlined />
                    </Button>
                </Space>
            ) : (
                <Spin tip="Sending..." size="large" spinning={isLoading}>
                    <Space direction="vertical" size="middle" className='DisplayFlex'>
                        <div className='CardTitle'>Copy Text</div>
                        <Form onFinish={onSend} form={form}>
                            <Form.Item name="text">
                                <TextArea
                                    autoSize={{ minRows: 4, maxRows: 6 }}
                                    allowClear
                                    placeholder="Copy your text to send here ..."
                                    onChange={onChangeValue}
                                />
                            </Form.Item>
                            <Form.Item>
                                <Button
                                    danger
                                    block
                                    type="primary"
                                    size="large"
                                    className='ButtonDiv'
                                    htmlType="submit"
                                    disabled={sendDisableStatus}
                                >
                                    Send
                                    <SendOutlined />
                                </Button>
                            </Form.Item>
                        </Form>
                    </Space>
                </Spin>
            )}
        </Card>
    );
};

export default SentCard;