import React, { useState, useEffect } from 'react';
import { Card, Input, Button, Space, Col, Row, message, Form, Spin } from 'antd';
import { DownloadOutlined, RollbackOutlined } from '@ant-design/icons';
import { getText } from '../api/textApi';

const { TextArea } = Input;

const RecievedCard = () => {
    const [isRecievedTab, setIsRecievedTab] = useState(false);
    const [recievedButtonDisable, setRecievedButtonDisable] = useState(false);
    const [recievedText, setRecievedText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [messageApi, contextHolder] = message.useMessage();
    const [form] = Form.useForm();

    useEffect(() => {
        const defaultValue = new URLSearchParams(window.location.search).get('code') || '';
        if (defaultValue.length >= 6) {
            form.setFieldsValue({ otp: defaultValue });
            setRecievedButtonDisable(true);
        }
    }, [form]);

    const onOTPFinish = () => {
        setRecievedButtonDisable(form.getFieldValue('otp').length === 6);
    };

    const onRecieved = async (values) => {
        setIsLoading(true);
        try {
            await getText(values.otp, (text) => {
                setIsRecievedTab(true);
                setRecievedText(text);
            }, () => messageApi.error('Not found'));
        } catch (error) {
            messageApi.error('Not found');
        } finally {
            setIsLoading(false);
        }
    };

    const onBack = () => {
        setIsRecievedTab(false);
        setRecievedButtonDisable(false);
        form.setFieldsValue({ otp: '' });
    };

    const onCopyText = () => {
        navigator.clipboard.writeText(recievedText);
        messageApi.success('Copied!');
    };

    return (
        <Card hoverable>
            {contextHolder}
            {isRecievedTab ? (
                <Space direction="vertical" size="middle" className='DisplayFlex'>
                    <div className='CardTitle'>Done!</div>
                    <TextArea readOnly autoSize={{ minRows: 4, maxRows: 6 }} value={recievedText} onClick={onCopyText} />
                    <Button danger block type="primary" size="large" className='ButtonDiv' onClick={onBack}>
                        Back
                        <RollbackOutlined />
                    </Button>
                </Space>
            ) : (
                <Spin tip="Receiving..." size="large" spinning={isLoading}>
                    <Space direction="vertical" size="middle" className='DisplayFlex'>
                        <div className='CardTitle'>Paste Text</div>
                        <Form form={form} onFinish={onRecieved}>
                            <Row gutter={[8, 16]} align="middle">
                                <Col flex="240px">
                                    <Form.Item name="otp">
                                        <Input size="large" maxLength={6} placeholder="Enter your pin..." onChange={onOTPFinish} />
                                    </Form.Item>
                                </Col>
                                <Col flex="auto">
                                    <Form.Item>
                                        <Button
                                            danger
                                            block
                                            type="primary"
                                            size="large"
                                            className='ButtonDiv'
                                            htmlType="submit"
                                            disabled={!recievedButtonDisable}
                                        >
                                            Receive
                                            <DownloadOutlined />
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Space>
                </Spin>
            )}
        </Card>
    );
};

export default RecievedCard;