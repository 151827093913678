import React from 'react'

import { Layout } from 'antd'

const { Footer } = Layout
const FooterApp = () => {
    return (
        <Footer id='Footer'>
            <span>TXTCP ©{new Date().getFullYear()}. Created by Quan Tran</span>
            </Footer>
    )
}

export default FooterApp