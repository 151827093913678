import React from 'react'
import { Layout } from 'antd'

const { Header } = Layout
const HeaderApp = () => {
    return (
        <Header
                id='Header'
            ><img src="/logo192.png" alt="logo" width={42} height={42}/> TXTCP</Header>
    )
}

export default HeaderApp